import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '15, 93, 181',
		'primary-dark': '5, 56, 114',
		'accent': '241, 101, 41',
		'accent-plus': '255, 255, 255',
	},
});
